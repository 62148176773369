<template>
  <div id="linkTransform">
    <vue-btn-return></vue-btn-return>
    <v-snackbar v-model="isSuccess" :timeout="timer" top right color="success">
      复制成功
      <!-- <v-btn text @click="isSuccess = false"> Close </v-btn> -->
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSuccess = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <div class="link__text mt-5" align="center" justify="center">
      <h2 class="mb-8">链接转换工具</h2>
    </div>
    <div class="custom-options">
      <v-row justify="right">
        <v-col cols="3">
          <v-text-field
            outlined
            label="查询"
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            outlined
            label="替换"
            v-model="replace"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            label="添加后缀"
            v-model="linkSuffix"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="box" align="center" justify="center">
      <div class="box-left">
        <v-textarea
          outlined
          name="ori"
          label="请输入原Facebook链接"
          autofocus
          height="60vh"
          hide-details="false"
          no-resize
          v-model="links"
        ></v-textarea>
      </div>
      <div class="box-center">
        <!-- <v-btn
          class="mb-4"
          color="error"
          depressed
          @click="isConvert = !isConvert"
          ><v-icon left dark>mdi-cached</v-icon>转换</v-btn
        > -->
        <v-btn class="mb-4" color="secondary" outlined depressed @click="links = ''"
          ><v-icon left dark>mdi-arrow-left</v-icon>清空</v-btn
        >
        <p class="mb-4">=></p>
        <v-btn color="primary" outlined depressed @click="copyLink()"
          >复制<v-icon right dark>mdi-arrow-right</v-icon></v-btn
        >
      </div>
      <div class="box-right">
        <v-textarea
          outlined
          name="aft"
          label="转换后的链接"
          height="60vh"
          hide-details="false"
          no-resize
          :value="transformLink"
          ref="transformLink"
          id="transformLink"
        ></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import BtnReturn from "../components/BtnReturn";
export default {
  name: "LinkTransform",
  data: () => ({
    isSuccess: false,
    timer: 3000,
    links: "",
    convertLink: "",
    isConvert: false,
    linkSuffix: "",
    search: "www",
    replace: ""
  }),
  components: {
    "vue-btn-return": BtnReturn,
  },
  methods: {
    copyLink() {
      if (this.links.trim()) {
        let links = this.$refs.transformLink;
        links.$el.querySelector("textarea").select();
        document.execCommand("copy");
        this.isSuccess = true;
      }
    },
    // convertLink(){
    //   this.isConvert = !this.isConvert
    //   let arr = this.links.split("\n");
    //   let newArr = arr.map(val => {
    //     let nVal = val.replace('www.facebook', 'm.facebook')
    //     return nVal + '?view=info'
    //   })
    //   this.convertLink = newArr.join("\n")
    // }
  },
  computed: {
    transformLink() {
      if (this.links) {
        let arr = this.links.split("\n");
        let newArr = arr.map((val) => {
          let newVal = val.replace(
            /^\/+|\/+$|\/(&|\?).*|\/(\s+)?"\w+\s\w+"$|\/+\s+$/g,
            ""
          );
          newVal += this.linkSuffix
          if (this.replace.trim() !== '') {
            newVal = newVal.replace(this.search.trim(), this.replace.trim())
          }
          // if (this.isConvert) {
          //   newVal = newVal.replace("www.facebook", "m.facebook");
          //   newVal = newVal + "?view=info";
          // }
          return newVal;
        });
        return newArr.join("\n");
      }
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
}

.box-center {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.box-left,
.box-right {
  width: 50%;
}

textarea {
  height: 60vh;
}
</style>